import { BaseModel, BaseModelData, Waitlist } from '@mmx/shared'
import { clone, cloneDeep, extend, find, get, identity, isEqual, isString, pickBy, trim, values } from 'lodash'
import moment from 'moment'

import { AddressSchema } from './schemas/address.schema'
import { PatientGuarantorSchema } from './schemas/patient-guarantor.schema'
import { PatientNameSchema } from './schemas/patient-name.schema'

export type PatientSexes = 'M' | 'F' | 'A' | 'O'

export interface Phone {
  n: string // phone number in E.164 format
  e?: string // phone extension
  t?: 'FAX' | 'CELL' | 'HOME' | 'WORK' | 'WORKFAX' | 'HOMEFAX'
  p?: boolean // primary phone
  ut?: boolean // untextable
  uf?: boolean // unfaxable
  uc?: boolean // uncallable
  dnt?: boolean // do not text
  dnc?: boolean // do not call
}

export interface PatientPaymentProvider {
  customerId?: string
  methodId?: string
}

export interface PatientData extends BaseModelData {
  clinicId?: string
  eid?: string
  name?: PatientNameSchema
  address?: AddressSchema
  phones?: Phone[]
  email?: string
  sex?: PatientSexes
  dob?: Date | string | moment.Moment
  vip?: boolean
  guarantor?: PatientGuarantorSchema
  paymentProvider?: PatientPaymentProvider
  waitlistPreferences?: Waitlist.PatientWaitListPreferences
}

export class Patient extends BaseModel {
  clinicId: string
  eid: string
  name: PatientNameSchema
  address: AddressSchema
  email: string
  phones: Phone[]
  dob: moment.Moment
  sex?: PatientSexes
  vip: boolean
  guarantor: PatientGuarantorSchema
  paymentProvider?: PatientPaymentProvider
  waitlistPreferences?: Waitlist.PatientWaitListPreferences

  constructor(data: PatientData) {
    super(data)

    this.clinicId = data.clinicId
    this.eid = data.eid
    this.name = data.name || { }
    this.phones = data.phones || []
    this.email = data.email
    this.sex = data.sex
    this.vip = !!data.vip
    this.paymentProvider = data.paymentProvider
    this.waitlistPreferences = data.waitlistPreferences

    if (data.address && !isEqual(values(data.address), ['US'])) {
      this.address = data.address
    } else {
      this.address = { }
    }

    if (data.dob) {
      this.dob = moment(data.dob)
    }

    if (data.guarantor) {
      this.guarantor = pickBy(data.guarantor, identity) as any

      if (data.guarantor.dob) {
        this.guarantor.dob = moment(data.guarantor.dob)
      }
    }
  }

  get phone(): string | void {
    let phone: Phone | void

    if (this.phones) {
      phone = find(this.phones, p => p.p)
      if (!phone && this.phones.length > 0) {
        phone = this.phones[0]
      }
    }

    return phone ? phone.n : null
  }

  get hasGuarantor(): boolean {
    return !!get(this, 'guarantor.name.family') || !!get(this, 'guarantor.name.given')
  }

  get shortName() {
    return (`${this.name.family}, ${this.name.given}`).toUpperCase()
  }

  get fullName() {
    const firstGroup = [
      this.name.family,
      this.name.suffix,
    ]

    const first = trim(firstGroup.join(' '))

    const secondGroup = [
      this.name.prefix ? this.name.prefix + '.' : '',
      this.name.given,
      this.name.nick ? `"${this.name.nick}"` : '',
      this.name.middle,
    ].filter((part) => {
      return part && part !== ''
    })

    const second = trim(secondGroup.join(' '))

    if (first && second) {
      return `${first}, ${second}`
    }

    return this.name.given
  }

  get hasAddress(): boolean {
    return isString(this.address.address1) && isString(this.address.zip)
  }

  get standardName() {
    if (this.name.family && this.name.given) {
      return `${this.name.given} ${this.name.family}`
    } else {
      return this.name.family || this.name.given || ''
    }
  }

  get text() {
    return this.shortName
  }

  toJSON() {
    const guarantor = cloneDeep(this.guarantor)

    if (guarantor && guarantor.dob) {
      guarantor.dob = (this.guarantor.dob as moment.Moment).format('YYYY-MM-DD')
    }

    return extend(super.toJSON(), {
      eid: this.eid,
      name: clone(this.name),
      address: clone(this.address),
      phones: this.phones,
      email: this.email,
      sex: this.sex,
      dob: this.dob ? this.dob.format('YYYY-MM-DD') : null,
      vip: this.vip === true,
      guarantor,
      waitlistPreferences: this.waitlistPreferences,
    })
  }
}
