import { cloneDeep, isArrayLike, isObjectLike, map } from 'lodash'
import moment from 'moment-timezone'

import { BaseModel } from '../base-model'
import { BaseModelData } from '../base-model-data'
import { Facility, FacilityData } from '../facility'
import { Patient, PatientData } from '../patient'
import { RequestedDateTime } from './appointment-request-requested-times'
import { APPOINTMENT_REQUEST_STATUS } from './appointment-request-status'
import { APPOINTMENT_REQUEST_TYPE } from './appointment-request-type'

import DurationConstructor = moment.unitOfTime.DurationConstructor
import { Resource, ResourceData } from '../resource.model'
import { ModelDate } from '../types/model-date'

type ProductData = any
type ProductModel = any

export interface AppointmentRequestData extends BaseModelData {
  appointmentId?: string
  requestedAt: ModelDate
  location?: FacilityData
  locationId: string
  notes?: string
  patient?: PatientData
  patientId: string
  products?: ProductData[]
  productIds: string[]
  productCategories?: string[]
  duration?: number
  requestedDates: string[]
  requestedDateTimes: RequestedDateTime[]
  status?: APPOINTMENT_REQUEST_STATUS
  type: APPOINTMENT_REQUEST_TYPE
  resources?: ResourceData[]
  newStartAt?: ModelDate
}

export class AppointmentRequest extends BaseModel {
  appointmentId?: string
  requestedAt?: moment.Moment
  locationId?: string
  location?: Facility
  notes?: string
  patientId?: string
  patient?: Patient
  priority?: number
  productIds?: string[]
  products?: ProductModel[]
  productCategories?: string[]
  resources?: Resource[]
  resourceIds?: string[]
  duration?: number
  requestedDates?: string[]
  requestedDateTimes?: RequestedDateTime[]
  status?: APPOINTMENT_REQUEST_STATUS
  type?: APPOINTMENT_REQUEST_TYPE
  newStartAt?: moment.Moment

  constructor(data?: AppointmentRequestData) {
    super(data)

    if (data) {
      this.appointmentId = data.appointmentId
      this.patientId = data.patientId
      this.locationId = data.locationId
      this.requestedAt = this.transformDate(data.requestedAt)
      this.notes = data.notes
      this.productCategories = data.productCategories
      this.duration = data.duration
      this.requestedDates = data.requestedDates
      this.requestedDateTimes = data.requestedDateTimes
      this.status = data.status
      this.type = data.type

      if (data.newStartAt) {
        this.newStartAt = this.transformDate(data.newStartAt)
      }

      if (data.patient) {
        this.patient = new Patient(data.patient)
      }

      if (data.location) {
        this.location = new Facility(data.location)
      }

      if (data.resources) {
        if (isObjectLike(data.resources[0])) {
          this.resources = map(
            data.resources,
            (resourceData) => new Resource(resourceData as ResourceData),
          )
          this.resourceIds = map(this.resources, (resource) => resource.id)
        } else {
          this.resourceIds = data.resources as any[]
        }
      }

      if (data.products) {
        this.products = map(data.products, productData => productData)
        this.productIds = data.productIds ?? map(this.products, product => product.id)
      }
    }
  }

  getRequestedTimes(timezone: string): moment.Moment[] {
    const format = 'YYYY-MM-DD HH:mm'
    return this.requestedDateTimes?.map(time => moment.tz(`${time.date} ${time.time}`, format, timezone)) ?? []
  }

  get showRequestedDateTimes(): moment.Moment[] {
    if (isArrayLike(this.requestedDateTimes) && this.requestedDateTimes.length > 0) {
      const dateTimes = this.requestedDateTimes.map((dateTime) => {
        return moment(dateTime.date).add(dateTime.time as DurationConstructor, 'HH:mm')
      })

      return dateTimes
    }

    return []
  }

  override toJSON(): any {
    let products: any = this.productIds

    if (this.products != null && this.products.length > 0) {
      products = this.products.map((product) => product.id)
    }

    return Object.assign(super.toJSON(), {
      patient: this.patientId,
      locationId: this.locationId,
      requestedAt: this.requestedAt?.toISOString(),
      status: this.status,
      priority: this.priority,
      notes: this.notes,
      products: cloneDeep(products),
      newStartAt: this.newStartAt?.toISOString(),
    })
  }
}
