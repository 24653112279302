import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import moment from 'moment-timezone'

export function minDateValidator(
  minDate: moment.Moment,
  currentValue?: moment.Moment,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value = moment(control.value, moment.HTML5_FMT.DATE)
    const forbidden = moment(minDate).isAfter(value, 'day')
    if (currentValue && currentValue.isSame(value, 'day')) {
      return {}
    }
    return forbidden ? { minDate: { value: control.value } } : {}
  }
}

export function maxDateValidator(maxDate: moment.Moment): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const forbidden = moment(maxDate).isBefore(
      moment(control.value, moment.HTML5_FMT.DATE),
      'day',
    )
    return forbidden ? { maxDate: { value: control.value } } : {}
  }
}

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value
    if (!value) {
      return null
    }

    const date = moment(value, moment.HTML5_FMT.DATETIME_LOCAL, true)

    if (!date.isValid()) {
      return { 'invalidDate': 'Invalid date' }
    }

    return null
  }
}

export function dateRangeValidator(startDateKey: string, endDateKey: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const startDate = formGroup.get(startDateKey)?.value
    const endDate = formGroup.get(endDateKey)?.value

    const endDateControl = formGroup.get(endDateKey)

    if (startDate && endDate && startDate > endDate) {
      const errors = endDateControl?.errors || {}
      errors['dateRangeInvalid'] = true
      endDateControl?.setErrors(errors)
      return { dateRangeInvalid: true }
    }

    if (endDateControl?.hasError('dateRangeInvalid')) {
      const errors = { ...endDateControl.errors }
      delete errors['dateRangeInvalid']

      if (Object.keys(errors).length > 0) {
        endDateControl.setErrors(errors)
      } else {
        endDateControl.setErrors(null)
      }
    }

    return null
  }
}
