import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { isArray, isDate, isObjectLike } from 'lodash'
import moment from 'moment-timezone'
import { Observable } from 'rxjs'

import { environment } from '../../../../environments'
import { RestResponse } from './rest/rest-response'

export interface HttpRequestOptions {
  headers?: HttpHeaders
  params?: HttpParams
  reportProgress?: boolean
  responseType?: 'json'
  withCredentials?: boolean
  body?: any
}

export interface ParamsObject {
  [key: string]:
    | string
    | number
    | boolean
    | undefined
    | null
    | Date
    | moment.Moment
    | (string | number)[]
}

@Injectable({
  providedIn: 'root',
})
export class RestService {
  constructor(protected http: HttpClient) {}

  convertToParams(input?: ParamsObject): HttpParams {
    const params: { [key: string]: string } = {}

    if (input && isObjectLike(input)) {
      for (const key of Object.keys(input)) {
        const value = input[key]
        if (isArray(value)) {
          if (value.length > 0) {
            params[key] = value.join(',')
          }
        } else if (typeof value === 'boolean') {
          params[key] = value ? 'true' : 'false'
        } else if (typeof value === 'string' && value.length > 0) {
          params[key] = value
        } else if (isDate(value) || moment.isMoment(value)) {
          params[key] = moment(value).format('YYYY-MM-DD')
        } else if (value != null) {
          params[key] = String(value)
        }
      }
    }

    return new HttpParams({ fromObject: params })
  }

  // retrieve something
  get<T>(
    route: string,
    params?: HttpParams,
    options: HttpRequestOptions = {},
  ): Observable<RestResponse<T>> {
    const url = this.getApiUrl(route)
    options.headers = this.getDefaultHeaders(options.headers)
    options.params = params
    options = this.getDefaultOptions(options)
    return this.http.get<RestResponse<T>>(url, options)
  }

  getCSV(
    route: string,
    params?: HttpParams,
    options: HttpRequestOptions = {},
  ): Observable<string> {
    const url = this.getApiUrl(route)
    options.headers = this.getDefaultHeaders(options.headers)
    options.params = params
    ;(options as any).responseType = 'text'
    return this.http.get<string>(url, options)
  }

  // create something
  post<T>(
    route: string,
    body: any,
    options: HttpRequestOptions = {},
  ): Observable<RestResponse<T>> {
    const url = this.getApiUrl(route)
    options.headers = this.getDefaultHeaders(options.headers)
    options = this.getDefaultOptions(options)
    return this.http.post<RestResponse<T>>(url, body, options)
  }

  // update something via replacing all of its data
  put<T>(
    route: string,
    body: any,
    options: HttpRequestOptions = {},
  ): Observable<RestResponse<T>> {
    const url = this.getApiUrl(route)
    options.headers = this.getDefaultHeaders(options.headers)
    options = this.getDefaultOptions(options)
    return this.http.put<RestResponse<T>>(url, body, options)
  }

  // partial update something
  patch<T>(
    route: string,
    body: any,
    options: HttpRequestOptions = {},
  ): Observable<RestResponse<T>> {
    const url = this.getApiUrl(route)
    options.headers = this.getDefaultHeaders(options.headers)
    options = this.getDefaultOptions(options)
    return this.http.patch<RestResponse<T>>(url, body, options)
  }

  // update something
  delete<T>(
    route: string,
    options: HttpRequestOptions = {},
  ): Observable<RestResponse<T>> {
    const url = this.getApiUrl(route)
    options.headers = this.getDefaultHeaders(options.headers)
    options = this.getDefaultOptions(options)
    return this.http.delete<RestResponse<T>>(url, options)
  }

  // api post
  apiPost<T>(
    url: string,
    body: any,
    options: HttpRequestOptions = {},
  ): Observable<T> {
    // options.headers = this.getDefaultHeaders(options.headers)
    // options = this.getDefaultOptions(options)
    return this.http.post<any>(url, body, options)
  }

  // post form data
  postForm(
    route: string,
    body: any,
    options: HttpRequestOptions = {},
  ): Observable<any> {
    const url = this.getApiUrl(route)
    return this.http.post<any>(url, body, options)
  }

  getApiUrl(route: string = '') {
    return this.getBaseApiUrl() + route
  }

  getUrl(route: string = '') {
    return this.resolve(this.getBaseUrl(), route)
  }

  protected getDefaultHeaders(headers?: HttpHeaders): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders()
    }

    if (!headers.has('Content-Type')) {
      headers.set('Content-Type', 'application/json')
    }

    return headers
  }

  protected getDefaultOptions(options: HttpRequestOptions): HttpRequestOptions {
    return Object.assign(
      {
        responseType: 'json',
      },
      options,
    )
  }

  private getBaseApiUrl() {
    return environment.api
  }

  private getBaseUrl() {
    return window.location.origin
  }

  private resolve(from: any, to: any) {
    const resolvedUrl = new URL(to, new URL(from, 'resolve://'))
    if (resolvedUrl.protocol === 'resolve:') {
      // `from` is a relative URL.
      const { pathname, search, hash } = resolvedUrl
      return pathname + search + hash
    }
    return resolvedUrl.toString()
  }
}
