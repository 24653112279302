import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.css'],
  standalone: false,
})
export class AppLoaderComponent {
  title: string
  message: string

  constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) { }
}
