import { BaseModelData, CRUD, CrudModel, ModelDate } from '@mmx/shared'
import moment from 'moment-timezone'

import { BlockedTimeRecurrence } from './blocked-time-recurrence'

export interface BlockedTimeData extends BaseModelData {
  description: string
  enabled?: boolean
  startAt?: ModelDate
  endAt?: ModelDate
}

@CRUD({
  base: '/crud/blocked-times',
})
export class BlockedTimeModel extends CrudModel {
  description?: string
  startAt?: moment.Moment
  endAt?: moment.Moment
  allDay?: boolean
  facilities?: string[]
  resources?: string[]
  recurrence?: BlockedTimeRecurrence
  reason?: string
  enabled?: boolean
  occurrences?: { start: string, end: string }[]

  override get sortKey(): string {
    return this.description?.toLowerCase() || this.id
  }

  get url(): string {
    return ['', 'settings', 'blocked-times', this.id].join('/')
  }

  constructor(data?: BlockedTimeData) {
    super(data, true)

    if (data?.startAt) {
      this.startAt = this.transformDate(data.startAt)
    }
    if (data?.endAt) {
      this.endAt = this.transformDate(data.endAt)
    }
  }

  override toJSON() {
    return Object.assign(super.toJSON(), {
      description: this.description,
      startAt: this.startAt?.toISOString(),
      endAt: this.endAt?.toISOString(),
      allDay: this.allDay,
      facilities: this.facilities,
      resources: this.resources,
      recurrence: this.recurrence,
      reason: this.reason,
      enabled: this.enabled,
    })
  }
}
