import { clone, cloneDeep, filter } from 'lodash'
import * as moment from 'moment'

import { standardizePhone } from '../../utils'
import { BaseModel } from '../base-model'
import { ClinicProvider } from '../clinic/clinic-provider'
import { TaxRate } from '../tax-rate/tax-rate.model'
import { FacilityAddressSchema } from './facility.address'
import { FacilityData } from './facility.data'
import { FacilityMMX } from './facility.mmx'
import { FacilityOptions } from './facility.options'
import { FacilityStatus } from './facility.status'
import { Timing } from './facility.timing'

export class Facility extends BaseModel {
  eid?: string
  mmx?: FacilityMMX
  name: string
  abbreviation: string
  phoneNumber?: string
  faxNumber?: string
  email?: string
  website?: string
  provider: ClinicProvider
  address?: FacilityAddressSchema
  options: FacilityOptions
  timing?: Timing
  timezone?: string
  status?: FacilityStatus
  placeOfServiceTypes?: string[]
  locality?: string
  image?: string
  defaultTaxes?: TaxRate[]
  soonestAvailableTime?: {
    date: string
    time: string
  }

  get displayName(): string {
    return this.options.displayName || this.name
  }

  constructor(data?: FacilityData) {
    super(data, true)

    this.name = data?.name ?? ''
    this.abbreviation = data?.abbreviation ?? ''
    this.phoneNumber = data?.phoneNumber
    this.faxNumber = data?.faxNumber
    this.email = data?.email
    this.address = data?.address
    this.options = data?.options ?? {}
    this.timing = data?.timing
    this.timezone = data?.timezone
    this.provider = data?.provider ?? {}

    this.name = data?.name ?? ''
    this.abbreviation = data?.abbreviation ?? ''
    this.options = data?.options ?? { }

    if (!this.locality) {
      this.locality = '0000000'
    }

    if (!this.placeOfServiceTypes || this.placeOfServiceTypes.length === 0) {
      this.placeOfServiceTypes = ['office']
    }

    if (!this.defaultTaxes || this.defaultTaxes.length === 0) {
      this.defaultTaxes = []
    }
  }

  get enabled(): boolean {
    return this.status === 'enabled'
  }

  get disabled(): boolean {
    return this.status === 'disabled'
  }

  get deleted(): boolean {
    return this.status === 'deleted'
  }

  get pending(): boolean {
    return this.status === 'pending'
  }

  get shortName(): string {
    return this.abbreviation || this.name
  }

  get fullName(): string {
    if (this.abbreviation === this.name) {
      return this.name
    } else {
      return `[${this.abbreviation}] ${this.name}`
    }
  }

  override get sortKey(): string {
    return this.name.toLowerCase()
  }

  get link(): string {
    return ['/settings', 'facilities', this.id, 'info'].join('/')
  }

  get mapLink(): string | void {
    if (this.address?.link) {
      return this.address.link
    } else if (this.address) {
      const addressString = filter([
        this.address.address1,
        this.address.address2,
        this.address.address3,
        this.address.city,
        this.address.state,
        this.address.country,
        this.address.zip,
      ]).join(', ')
      return `https://maps.google.com/?q=${addressString}`
    }
  }

  get soonestAvailable() {
    if (this.soonestAvailableTime) {
      return moment.utc(`${this.soonestAvailableTime.date} ${this.soonestAvailableTime.time}`)
    }
    return ''
  }

  override toJSON() {
    return {
      ...super.toJSON(),
      eid: this.eid,
      mmx: this.mmx,
      name: this.name,
      abbreviation: this.abbreviation,
      phoneNumber: standardizePhone(this.phoneNumber),
      faxNumber: standardizePhone(this.faxNumber),
      email: this.email,
      website: this.website,
      provider: clone(this.provider),
      address: clone(this.address),
      options: clone(this.options),
      timing: cloneDeep(this.timing),
      timezone: this.timezone,
      placeOfServiceTypes: this.placeOfServiceTypes,
      locality: this.locality,
      image: this.image,
      status: this.status,
      defaultTaxes: this.defaultTaxes,
    }
  }

  clone(): Facility {
    return new Facility(this.toJSON())
  }
}
