import { BaseModel, BaseModelData } from '@mmx/shared'
import * as _ from 'lodash'
import moment from 'moment'

import { CustomQuestionSchema } from './schemas/custom-question.schema'

export interface IntakeData extends BaseModelData {
  patientId: string
  stage: string
}

export class IntakeModel extends BaseModel {
  patientId: string
  stage: Intake.STAGE
  dob?: moment.Moment
  guarantorDOB?: moment.Moment
  hasInsurance?: boolean
  insuranceCarrier?: string
  insuranceNumber?: number
  customQuestions?: CustomQuestionSchema[]

  constructor(data: any) {
    super(data)

    _.extend(this, _.omit(data, ['updatedAt', 'createdAt', 'id']))

    this.dob = this.transformDate(data.dob)
    this.guarantorDOB = this.transformDate(data.guarantorDOB)
  }

  get date() {
    return this.updatedAt ? this.updatedAt.format('LL') : ''
  }

  get text() {
    return 'Intake on ' + this.date
  }

  toJSON() {
    return _.cloneDeep(this)
  }
}

export namespace Intake {
  export enum STAGE {
    PENDING = 'P',
    DELIVERED = 'D',
    SUBMITTED = 'S',
    CONFIRMED = 'C',
    SYNCING = 'U',
    TECH_CONFIRMED = 'T',
    TECH_REVIEW = 'W',
    SYNCED = 'Y',
  }
}
