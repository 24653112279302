import { extend } from 'lodash'
import moment from 'moment-timezone'

import { BaseModelData } from './base-model-data'

export class BaseModel {
  id = ''
  clinicId?: string
  _shortId?: string

  // todo optional createdAt/updatedAt differs from declaration in apps; check if it is possible, update accordingly
  createdAt?: moment.Moment
  updatedAt?: moment.Moment

  get sortKey(): string {
    return this.id
  }

  get isNew(): boolean {
    return !this.id
  }

  get shortId() {
    return this._shortId || this.id.substring(0, 8)
  }

  set shortId(value: string) {
    this._shortId = value
  }

  constructor(data?: BaseModelData, extendAll = false) {
    if (data) {
      if (extendAll) {
        extend(this, data)
      }

      this.id = data.id || '' // todo check optional id in BaseModelData
      this.clinicId = data.clinicId

      if (data.createdAt) {
        this.createdAt = this.transformDate(data.createdAt)
      }

      if (data.updatedAt) {
        this.updatedAt = this.transformDate(data.updatedAt)
      }
    }
  }

  toJSON(): any {
    return {
      id: this.id,
    }
  }

  protected transformDate(
    date: string | Date | moment.Moment,
    useLocal = true,
  ) {
    const m = moment.utc(date, moment.ISO_8601, true)
    // if we want only a date and not a datetime object, then calling .local()
    // will shift the date and we do not want that. If it includes time, however,
    // we want to shift it to a local time
    return useLocal ? m.local() : m
  }
}
