import { BaseModel, BaseModelData, Facility, FacilityData } from '@mmx/shared'
import * as _ from 'lodash'
import moment from 'moment-timezone'

import { Patient, PatientData } from './patient.model'
import { ShoppableServiceModel } from './shoppable-service.model'
import DurationConstructor = moment.unitOfTime.DurationConstructor

export interface AppointmentScheduleData extends BaseModelData {
  requestedAt: Date | string | moment.Moment
  location?: FacilityData
  locationId: string
  patientId: string
  patient?: PatientData
  shoppableServiceId: string
  shoppableService?: ShoppableServiceModel
  requestedDateTimes: ScheduledDateTimes[]
  notes?: string
  status?: string
}

export class ScheduledDateTimes {
  date: string
  time: string
  precision: 'exact' | 'approx'
}

export class AppointmentScheduleModel extends BaseModel {
  requestedAt: moment.Moment
  locationId?: string
  resourceId?: string
  location?: Facility
  patientId: string
  patient?: Patient
  shoppableServiceId: string
  shoppableService: ShoppableServiceModel
  updatedAt: moment.Moment
  requestedDateTimes: ScheduledDateTimes[]
  notes: string
  status?: string

  constructor(data?: AppointmentScheduleData) {
    super(data)

    if (data) {
      this.patientId = data.patientId
      this.shoppableServiceId = data.shoppableServiceId
      this.locationId = data.locationId
      this.requestedAt = this.transformDate(data.requestedAt)
      this.notes = data.notes
      this.requestedDateTimes = data.requestedDateTimes
      this.status = data.status

      if (data.patient) {
        this.patient = new Patient(data.patient)
      }

      if (data.shoppableService) {
        this.shoppableService = new ShoppableServiceModel(data.shoppableService)
      }

      if (data.location) {
        this.location = new Facility(data.location)
      }
    }
  }

  get showRequestedDateTimes() {
    if (_.isArrayLike(this.requestedDateTimes) && this.requestedDateTimes.length > 0) {
      const dateTimes = this.requestedDateTimes.map((dateTime) => {
        return moment(dateTime.date).add(dateTime.time as DurationConstructor, 'HH:mm')
      })

      return dateTimes
    }

    return []
  }

  toJSON(): any {
    return _.extend(super.toJSON(), {
      patient: this.patientId,
      layService: this.shoppableServiceId,
      locationId: this.locationId,
      requestedAt: this.requestedAt ? this.requestedAt.toISOString() : '',
      status: this.status,
      notes: this.notes,
    })
  }
}

export namespace AppointmentSchedule {
  export enum STATUS {
    NEW = 'N',
    PENDING = 'P',
    SCHEDULED = 'S'
  }
}
