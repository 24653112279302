import { BaseModel, BaseModelData } from '@mmx/shared'
import * as _ from 'lodash'

export interface ProductCategoryData extends BaseModelData {
  label: string
  color: string
}

export class ProductCategory extends BaseModel {
  label: string
  color: string

  constructor(data: ProductCategoryData) {
    super(data)
    this.label = data.label
    this.color = data.color
  }

  toJSON() {
    return _.extend(super.toJSON(), {
      label: this.label,
      color: this.color,
    })
  }

}
