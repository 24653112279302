import { BaseModel, BaseModelData, TransactionMethod } from '@mmx/shared'
import moment from 'moment'

import { ITransactionDetails } from './transaction-details.model'

export interface TransactionData extends BaseModelData {
  name?: string
  referenceId: string
  patientId: string
  clinicId: string
  clinicianId: string
  createdAt: moment.Moment
  status: Transaction.STATUS
  method: TransactionMethod
  type: Transaction.TYPE
  amount: number
  currency: string
  details: ITransactionDetails
}

export class TransactionModel extends BaseModel {
  name?: string
  referenceId: string
  patientId: string
  clinicId: string
  clinicianId: string
  createdAt: moment.Moment
  status: Transaction.STATUS
  method: TransactionMethod
  type: Transaction.TYPE
  amount: number
  currency: string
  details: ITransactionDetails

  constructor(data: TransactionData) {
    super(data, true)

    if (data) {
      this.name = data.name
      this.referenceId = data.referenceId
      this.patientId = data.patientId
      this.clinicId = data.clinicId
      this.clinicianId = data.clinicianId
      this.createdAt = data.createdAt
      this.status = data.status
      this.method = data.method
      this.type = data.type
      this.amount = data.amount
      this.currency = data.currency
      this.details = data.details
    }
  }

  get text() {
    return 'Transaction on ' + this.createdAt
  }

  get statusDisplay(): string {
    let statusDisplay: string
    switch (this.status) {
      case Transaction.STATUS.SUCCESS:
        statusDisplay = 'Success'
        break
      case Transaction.STATUS.FAILED:
        statusDisplay = 'Failed'
        break
      case Transaction.STATUS.PENDING:
        statusDisplay = 'Pending'
        break
      default:
        statusDisplay = ''
        break
    }
    return statusDisplay
  }

  get methodDisplay(): string {
    let methodDisplay: string
    switch (this.method) {
      case TransactionMethod.CREDIT_CARD:
        methodDisplay = 'Credit Card'
        break
      case TransactionMethod.CASH:
        methodDisplay = 'Cash'
        break
      case TransactionMethod.CHECK:
        methodDisplay = 'Check'
        break
      case TransactionMethod.APPLE_PAY:
        methodDisplay = 'Apple Pay'
        break
      case TransactionMethod.GOOGLE_PAY:
        methodDisplay = 'Google Pay'
        break
      case TransactionMethod.STAFF_CREDIT_CARD:
        methodDisplay = 'Staff Credit Card'
        break
      case TransactionMethod.PATIENT_IVR:
        methodDisplay = 'Patient IVR'
        break
      default:
        methodDisplay = ''
        break
    }
    return methodDisplay
  }

  get typeDisplay(): string {
    let typeDisplay: string
    switch (this.type) {
      case Transaction.TYPE.PAYMENT:
        typeDisplay = 'Payment'
        break
      case Transaction.TYPE.REFUND:
        typeDisplay = 'Refund'
        break
      case Transaction.TYPE.DISPUTE:
        typeDisplay = 'Dispute'
        break
      default:
        typeDisplay = ''
        break
    }
    return typeDisplay
  }
}

export namespace Transaction {
  export enum STATUS {
    SUCCESS = 'S',
    FAILED = 'F',
    PENDING = 'P',
  }

  export enum TYPE {
    PAYMENT = 'P',
    REFUND = 'R',
    DISPUTE = 'D',
  }
}
