import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { filter } from 'rxjs'

import { environment } from '../../../environments/environment'
import { Clinic } from '../../shared/models'
import { AppTheme } from '../../shared/models/app-theme'
import { ClinicType } from '../../shared/models/clinic/clinic-type'
import { ClinicService } from './clinic.service'
import { I18nService } from './i18n.service'

@Injectable({
  providedIn: 'root',
})
export class AppService {
  site: 'patientpal' | 'healthlogix' | 'expeditor' = 'patientpal'
  name = 'Med-Metrix'
  slogan = 'From Clipboard to Claim'
  company = 'Med-Metrix LLC'
  privacyUrl = 'https://www.patientpal.com/privacy.html'
  tosUrl = 'https://www.patientpal.com/provider-terms.html'
  supportUrl = 'https://help.patientpal.com'
  supportEmail = 'support@patientpal.com'
  supportPhone = '+18887281368'
  defaultTheme = 'green'
  primaryDomain = '.patientpal.com'
  logoSrc: string = null
  title = 'Med-Metrix | Patient portal'
  metaDescription = 'The Perfect End-to-End Automated Front Desk Solution.'
  clinic: Clinic
  currentTheme: string
  currentLanguage: string = 'en'

  favicon: HTMLLinkElement = document.querySelector('#favicon')
  favicon16: HTMLLinkElement = document.querySelector('#favicon16')
  favicon32: HTMLLinkElement = document.querySelector('#favicon32')
  faviconApple: HTMLLinkElement = document.querySelector('#faviconApple')

  get savedTheme(): string | null {
    try {
      return localStorage.getItem('ppal-theme') || null
    } catch (e) {
      // Safari private mode
      return null
    }
  }

  set savedTheme(theme: string | null) {
    try {
      if (theme) {
        localStorage.setItem('ppal-theme', theme)
      } else {
        localStorage.removeItem('ppal-theme')
      }
    } catch (e) {
      // Safari private mode
    }
  }

  constructor(
    private clinicService: ClinicService,
    private titleService: Title,
    private metaService: Meta,
    private i18n: I18nService,
  ) {
    this.detect()

    this.clinicService.changes
      .pipe(
        filter(Boolean),
      )
      .subscribe((clinic) => {
        this.clinic = clinic
        this.currentLanguage = this.i18n.currentLang
        this.detect()
      })
  }

  detect() {
    const type = this.clinic ? this.clinic.type : ''
    // patientpal:whitelabel
    // if this is *.expeditor.app in production, or for development, anything containing "expeditor" (update /etc/hosts file)
    if (
      type === ClinicType.EXPEDITOR ||
      location.hostname.includes('.expeditor.app') ||
      (environment.env === 'dev' && location.hostname.includes('expeditor'))
    ) {
      this.site = 'expeditor'
      this.name = 'Xtrac'
      this.slogan = 'Performance'
      this.company = 'Expeditor Systems'
      this.defaultTheme = AppTheme.INDIGO
      this.logoSrc = '/assets/images/expeditor.png'
      this.title = 'Expeditor | Patient portal'
      this.metaDescription = 'The Perfect End-to-End Automated Front Desk Solution.'
      this.favicon.href = '/assets/images/clients/expeditor/favicon.ico'
      this.favicon16.href = '/assets/images/clients/expeditor/favicon-16x16.png'
      this.favicon32.href = '/assets/images/clients/expeditor/favicon-32x32.png'
      this.faviconApple.href = '/assets/images/clients/expeditor/apple-icon-180x180'
      // this.primaryDomain = '.expeditor.app'
    } else if (
      type === ClinicType.HEALTH_LOGIX ||
      location.hostname.includes('healthlogix')
    ) {
      this.site = 'healthlogix'
      this.name = 'Health Logix'
      this.slogan = 'Efficiency Through Automation'
      this.company = 'Health Logix'
      this.defaultTheme = AppTheme.ORANGE
      this.logoSrc = '/assets/images/healthlogix.png'
      this.supportPhone = '+16153920061'
      this.title = 'Health Logix | Patient portal'
      this.metaDescription = 'The Perfect End-to-End Automated Front Desk Solution.'
      this.favicon.href = '/assets/images/clients/healthlogix/favicon.ico'
      this.favicon16.href = '/assets/images/clients/healthlogix/favicon-16x16.png'
      this.favicon32.href = '/assets/images/clients/healthlogix/favicon-32x32.png'
      this.faviconApple.href = '/assets/images/clients/healthlogix/apple-icon-180x180'
    }

    if (this.clinic && this.clinic.settings.portalTheme) {
      this.setTheme(this.clinic.settings.portalTheme)
    } else if (this.savedTheme != null) {
      this.setTheme(this.savedTheme)
    } else {
      this.setTheme(this.defaultTheme)
    }

    this.setPage()
  }

  setTheme(theme: string) {
    const html = document.querySelector('html')

    if (this.currentTheme == null) {
      html.classList.remove(`theme-${this.currentTheme}`)
    }

    html.classList.forEach((className) => {
      if (className.startsWith('theme-')) {
        html.classList.remove(className)
      }
    })

    if (!Object.values<string>(AppTheme).includes(theme)) {
      theme = this.defaultTheme
    }

    this.currentTheme = theme

    html.classList.add(`theme-${theme}`)
  }

  setPage() {
    this.titleService.setTitle(this.name)
    this.metaService.addTags([
      // { name: 'keywords', content: 'Healthcare, clinic, united state'},
      { name: 'description', content: this.slogan },
    ])
  }
}
